<template>
  <div class="c-container ui-h-full">
    <div class="c-row c-center ui-h-full">
      <div class="c-col-full sm:c-col-4 md:c-col-8 lg:c-col-6">
        <div class="ui-relative c-bg-white ui-rounded-md">
          <!-- Content -->
          <div class="c-bg-white ui-p-8">
            <!-- Título -->
            <div class="c-text-h3 c-text-gray ui-mb-2">
              Convidar Usuário
            </div>

            <!-- Subtítulo -->
            <div class="c-text-b2 c-text-gray ui-mb-6">
              Convidar um usuário para utilizar o sistema
            </div>

            <!-- Formulário -->
            <p-form ref="form">
              <!-- Email -->
              <div class="c-row c-horizontal-end">
                <div class="c-col">
                  <p-input
                    data-cy="email-field"
                    label="Email"
                    class="ui-mb-4"
                    v-model.trim="user.email"
                    :validator="validateEmail"
                  />
                </div>
              </div>

              <!-- Nome -->
              <div class="c-row c-horizontal-end">
                <div class="c-col">
                  <p-input
                    data-cy="name-field"
                    label="Nome completo"
                    class="ui-mb-4"
                    v-model.trim="user.full_name"
                    :validator="validateName"
                  />
                </div>
              </div>

              <!-- Grupo -->
              <!-- TODO: Tornar obrigatório -->
              <div class="c-row c-horizontal-end">
                <div class="c-col">
                  <c-dropdown
                    label="Selecionar Grupo"
                    class="ui-mb-4"
                    :options="groupsOptions"
                    :value="selectedGroup"
                    @change="handleChange"
                    max-height
                  />
                </div>
              </div>

              <div class="c-row c-horizontal-end">
                <div class="c-col-1/2">
                  <c-button
                    full
                    outline
                    data-cy="cancelarButton"
                    @click="$modal.hide()"
                    :disabled="isFormSending"
                  >
                    Cancelar
                  </c-button>
                </div>

                <div class="c-col-1/2">
                  <c-button
                    full
                    data-cy="convidarButton"
                    type="submit"
                    @click="inviteUser"
                    :disabled="!isFormValid()"
                    :loading="isFormSending"
                  >
                    Convidar
                  </c-button>
                </div>
              </div>
            </p-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PInput from '../../components/p-input';
import PForm from '../../components/p-form';
import { validateEmail, validateName } from '../../utils/validateUser';
import { CButton, CDropdown } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'InviteUserModal',
  components: {
    PInput,
    PForm,
    CDropdown,
    CButton,
  },
  data () {
    return {
      validateEmail,
      validateName,
      isFormSending: false,
      user: {
        email: '',
        full_name: '',
        group: null,
      },
      groupsOptions: [],
      selectedGroup: {},
    };
  },
  async mounted () {
    const groups = await this.$s.group.getAll();
    this.groupsOptions = groups.map(g => ({ label: g.name, value: g.id }));
  },
  methods: {
    isFormValid () {
      return this.$refs.form?.valid();
    },
    async inviteUser () {
      if (this.isFormValid()) {
        this.isFormSending = true;
        const payload = {
          user_type: 'manager',
          user_data: { ...this.user },
          target: this.$env.INVITE_USER_TARGET_URL,
        };

        const selectedGroupId = this.selectedGroup.value;
        if (selectedGroupId) {
          payload.user_data.group = selectedGroupId;
        }

        await this.$s.user.inviteUser(payload).finally(() => this.isFormSending = false);
        this.$toast.show({ type: 'success', text: 'Usuário convidado', timeout: 3000 });
        this.$modal.hide();
      }
    },
    handleChange (value) {
      this.selectedGroup = value;
    },
  },
};
</script>
