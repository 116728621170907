<template>
  <span
    v-if="isLoading"
    class="c-card-loading__animation ui-opacity-32 ui-rounded-full c-text-transparent ui-h-4 ui-inline-flex"
    :class="`ui-w-${size}`"
  />

  <span
    v-else-if="breakAt"
    v-on="$listeners"
  >
    {{ brokenText[0] }}{{ brokenText[1] }}<wbr>{{ brokenText[2] }}
  </span>

  <span
    v-else
    v-on="$listeners"
  > {{ text }}
  </span>
</template>

<script>
import { createMask } from 'imask';

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    mask: {
      type: [String, Array, RegExp],
      default: null,
    },
    size: {
      type: [String, Number],
      default: 52,
    },
    breakAt: {
      type: String,
      default: null,
    },
  },
  computed: {
    text () {
      let output = this.value;

      if (this.mask) {
        const template = Array.isArray(this.mask) ? this.mask : [this.mask];
        const masked = createMask({ mask: template.map(val => ({ mask: val })) });
        output = masked.resolve(this.value);
      }

      return output;
    },
    brokenText () {
      const parts = this.text.split(this.breakAt);
      return [parts[0], this.breakAt, parts[1]];
    },
  },
};
</script>
