/******************/
// Cópia do Perfil//
/******************/

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const CPF_REGEX = /^\d{11}$/; // 11 dígitos
const MOBILE_REGEX = /^\d{11}$/; // 11 dígitos
const CEP_REGEX = /^\d{8}$/; // 8 dígitos

// https://stackoverflow.com/a/46181/8038345
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateName(name) {
  if (!name) { return 'Nome é obrigatório' }
  if (!name.includes(' ') || name.length < 4) { return 'Nome completo inválido' }
}

export function validateMobile(phone) {
  if (!phone) { return 'Celular é obrigatório' }
  if (!MOBILE_REGEX.test(phone)) { return 'Celular inválido' }
}

export function validatePassword(password) {
  if (!password) { return 'Senha é obrigatória' }
  if (password.length < 5) { return 'A senha precisa ter no mínimo 5 caracteres' }
}

export function validateEmail(email) {
  if (!email) { return 'Email é obrigatório' }
  if (!EMAIL_REGEX.test(email)) { return 'Email inválido' }
}

export function validateConfirmPassword(confirmPassword, password) {
  if (!confirmPassword) { return 'Confirmação de senha é obrigatória' }
  if (confirmPassword !== password) { return 'Senhas não são iguais' }
}

export function validateCPF(cpf) {
  if (!cpf) { return 'CPF é obrigatório' }
  if (!CPF_REGEX.test(cpf)) { return 'O CPF precisa ter 11 caracteres numéricos' }
  if (!isCpfValid(cpf)) { return 'CPF inválido' }
}

export function validateCurrentPassword(password) {
  if (!password) { return 'Senha atual é obrigatória' }
}

export function validateZipCode(zipCode) {
  if (!zipCode) return 'O campo CEP é obrigatório';
  if (!CEP_REGEX.test(zipCode)) { return 'Inserir os 8 números do CEP' }
}

const areAllDigitsEqual = (str) => str.split('').every(letra => letra === str[0]);

function isCpfValid(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (areAllDigitsEqual(cpf)) { return false }

  function validateDigit(digit) {
    let add = 0;
    const init = digit - 9;
    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i + init)) * (i + 1);
    }
    return (add % 11) % 10 === parseInt(cpf.charAt(digit));
  }

  return validateDigit(9) && validateDigit(10);
}

export function validateBirthday(date) {
  if (!date) { return 'Data de nascimento é obrigatório' }

  const min = dayjs().subtract(120, 'years');
  const max = dayjs();
  const birthdate = dayjs(date);

  if (!birthdate.isBetween(min, max)) {
    return 'Data de nascimento inválida';
  }
}
