<template>
  <div class="c-row">
    <div class="ui-m-4 ui-my-8 c-col">
      <!-- Título -->
      <div class="c-row ui-mb-4">
        <div class="c-col">
          <span class="c-text-h2 c-text-gray-400">
            Usuários
          </span>
        </div>
      </div>

      <!-- Subtítulo -->
      <div class="c-row c-vertical-center ui-justify-between ui-mb-4">
        <!-- Subtítulo -->
        <div class="c-col md:c-col-8">
          <span class="c-text-subtitle c-text-gray-300">
            Gerencie os usuários da Estratégia
          </span>
        </div>
      </div>

      <!-- Barra de pesquisa e Botões Convidar usuário e Grupos de usuários -->
      <div class="c-row ui-mb-4 c-vertical-center ui-justify-between ui-mb-4">
        <!-- Barra de pesquisa -->
        <div class="c-col md:c-col-5">
          <c-search-bar
            v-model="term"
            size="lg"
            placeholder="Busque por usuário"
            data-cy="search-user"
          />
        </div>

        <!-- Botão Convidar usuário -->
        <div class="c-col c-d-flex ui-justify-end">
          <div
            v-if="showGroupsPage"
            class="ui-mr-4"
            style="max-width: 264px;"
          >
            <c-button
              full
              outline
              title="Grupos"
              data-cy="gruposButton"
              @click="$router.push({ name: 'groups' })"
            >
              <c-icon
                class="ui-mr-2"
                outline
                color="inherit"
                name="group"
              />
              Grupos
            </c-button>
          </div>

          <div
            v-if="showInviteUser"
            style="max-width: 264px;"
          >
            <c-button
              full
              outline
              data-cy="convidarButton"
              title="Convidar usuário"
              @click="openInviteUserModal"
            >
              <c-icon
                class="ui-mr-2"
                outline
                color="inherit"
                name="add"
              />
              Convidar usuário
            </c-button>
          </div>
        </div>
      </div>

      <!-- Hint de busca -->
      <div
        class="c-row ui-mb-4"
        v-if="editedSearch"
      >
        <div class="c-col">
          <span class="c-text-b3">
            Pesquisando por "{{ editedSearch.editedQuery }}". Você realmente quis pesquisar por
            <span
              role="button"
              class="ui-underline"
              @click="searchByExactTerm"
            >
              {{ editedSearch.oldSearch }}
            </span>?
          </span>
        </div>
      </div>

      <!-- Tabela -->
      <c-table
        :columns="columns"
        :data="users"
        :items-per-page="itemsPerPage"
        :pagination="pagination"
        row-variable-height
        @change-order-by="changeOrderBy"
        @change-pagination-per-page="changePaginationPerPage"
        @change-pagination-current-page="changePaginationCurrentPage"
      >
        <template v-slot:column-is_active="{ item: user }">
          <c-tag
            class="ui-mt-1"
            :background-color="user.is_active ? 'var(--c-color-green)' : 'var(--c-color-gray)'"
            :text-color="'var(--c-color-white)'"
          >
            {{ user.is_active ? 'ATIVO' : 'INATIVO' }}
          </c-tag>
        </template>

        <template v-slot:column-email="{ item: user }">
          <p-span
            :value="user.email"
            break-at="@"
            data-cy="columnEmail"
          />
        </template>

        <template v-slot:column-cpf="{ item: user }">
          <span>
            {{ user.cpf | mask('000.000.000-00') }}
          </span>
        </template>

        <template v-slot:column-actions="{ item: user }">
          <button
            :data-cy="`openDetailsPage-${ user.email }`"
            @click="openDetailsPage(user.id)"
            class="c-text-primary c-row"
          >
            <c-icon
              name="user"
              class="c-d-flex c-center ui-mr-1"
            />
            <span class="c-d-flex">
              Perfil
            </span>
          </button>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { CButton, CTag, CSearchBar, CIcon } from '@estrategiahq/coruja-web-ui';
import InviteUserModal from './InviteUserModal';
import CTable from '../../components/c-table/CTable';
import PSpan from '../../components/p-span';
import { requiredPermissionsForUsersGroupsPage } from '../../constants';
import { maskValue } from '@/utils/strings';

const mayBeMaskedCpf = (val = '') => val.length !== 11 && val.replace(/\D/g, '').length === 11; // Removendo campos não numéricos, tem 11 dígitos
const mayBeMaskedMobile = (val = '') => val.length !== 11 && val.replace(/\D/g, '').length === 11; // Removendo campos não numéricos, tem 11 dígitos

const itemsPerPage = [30, 50, 100];

export default {
  components: {
    CButton,
    CSearchBar,
    CTable,
    CTag,
    PSpan,
    CIcon,
  },
  data () {
    return {
      itemsPerPage,
      term: this.$route.query.q || '',
      editedSearch: {
        oldSearch: '',
        editedQuery: '',
        pagination: null,
      },
      columns: [
        { attribute: 'full_name', label: 'Nome', isOrderable: true },
        { attribute: 'email', label: 'Email', flexWeight: 2 },
        { attribute: 'cpf', label: 'CPF', maxWidth: '140px' },
        { attribute: 'is_active', label: 'Status da Conta', maxWidth: '120px' },
        { attribute: 'actions', label: 'Ações', justifyContent: 'center' },
      ],
      users: [],
      pagination: {
        orderBy: 'updated_at',
        sort: 'desc',
        totalPages: 1,
        perPage: itemsPerPage[0],
        currentPage: 1,
      },
    };
  },
  filters: {
    mask: maskValue,
  },
  props: {
    loggedUser: { type: Object, required: true },
  },
  mounted () {
    this.search();
  },
  watch: {
    term: debounce(function () {
      this.pagination.currentPage = 1;
      this.updateQuery('q', this.term);
      this.search();
    }, 1000),
  },
  computed: {
    showInviteUser () {
      return this.loggedUser?.hasPermission('accounts.user.invite-manager');
    },
    showGroupsPage () {
      return requiredPermissionsForUsersGroupsPage.some(p => this.loggedUser?.hasPermission(p));
    },
  },
  methods: {
    updateQuery(key, value) {
      if (this.$route.query[key] != value) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            [key]: value,
          },
        });
      }
    },
    openGroupsPage (id) {
      this.$router.push({ name: 'users-groups', params: { id } });
    },
    openDetailsPage (id) {
      this.$router.push({ name: 'user-detail', params: { id } });
    },
    openPaymentPage (id) {
      this.$router.push({ name: 'user-payment', params: { id } });
    },
    changeOrderBy (newOrder) {
      this.pagination.currentPage = 1;
      this.pagination.orderBy = newOrder.attribute;
      this.pagination.sort = newOrder.method.toLowerCase();
      this.search();
    },
    changePaginationPerPage (newPerPage) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = newPerPage;
      this.search();
    },
    changePaginationCurrentPage (newPage) {
      this.pagination.currentPage = newPage;
      this.search();
    },
    async openInviteUserModal () {
      this.$modal.show({ component: InviteUserModal, overlayOpacity: 0.7 });
    },
    searchByExactTerm () {
      this.term = this.editedSearch.oldSearch;
      this.updateQuery('q', this.term);
      this.search({ exact: true });
    },
    async search ({ exact = false } = {}) {
      // Limpa o estado
      this.editedSearch = null;

      let searchQuery = this.term;
      if (!exact && (mayBeMaskedCpf(this.term) || mayBeMaskedMobile(this.term))) {
        searchQuery = this.term.replace(/\D/g, '');

        this.editedSearch = {
          pagination: this.pagination,
          oldSearch: this.term,
          editedQuery: searchQuery,
        };
      }

      sessionStorage.setItem('users-list-search-url', location.href);
      const { users, totalPages } = await this.$s.user.search({ ...this.pagination, term: searchQuery });

      this.users = users;
      this.pagination.totalPages = totalPages;

      if (users.length === 0) {
        this.$toast.show({
          type: 'error',
          text: 'Não há usuários',
        });
      }
    },
  },
};
</script>
